import { useMemo } from 'react';

// Creates a unique id for the collapsible section
export const useId = () =>
	useMemo(() => {
		const randomId = Math.floor(10000000 + Math.random() * 90000000); // Generates an 8-digit number
		return `collapsible-section-${randomId}`;
	}, []);

// Returns a valid id for the collapsible section controlled by the header
export const getSectionId = (headerId: string) => `${headerId}-content`;
